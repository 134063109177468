








































































































































































import {Vue, Component} from "vue-property-decorator";
import QuestListItem from "@/components/QuestListItem.vue";
import {IndexDBClient} from "@/api/indexeddb/IndexDBClient";

@Component({
  name: "CMSResponsive",
  components:{ QuestListItem}
})

export default class CMSResponsive extends Vue {
  protected sidebarOpen = false;
  protected notificationOpen = false;
  protected settingsOpen = false;
  protected dropdownOpen = false;

  protected numberOfCards = 0;
  protected numberOfBoards = 0;
  protected numberOfCardTypes = 0;
  protected numberOfCardStatuses = 0;
  protected numberOfUsers = 0;

  protected database = new IndexDBClient();

  get getNumberOfCards() {
    this.database.cards.then( (cards: any) => {
      this.numberOfCards = cards.length;
    });
    return this.numberOfCards;
  }

  get getNumberOfBoards() {
    this.database.boards.then( (cards: any) => {
      this.numberOfBoards = cards.length;
    });
    return this.numberOfBoards;
  }

  get getNumberOfCardTypes() {
    this.database.cardTypes.then( (cards: any) => {
      this.numberOfCardTypes = cards.length;
    });
    return this.numberOfCardTypes;
  }

  get getNumberOfCardStatuses() {
    this.database.cardTypes.then( (cards: any) => {
      this.numberOfCardStatuses = cards.length;
    });
    return this.numberOfCardStatuses;
  }

  get getNumberOfUsers() {
    this.database.cardTypes.then( (cards: any) => {
      this.numberOfUsers = cards.length;
    });
    return this.numberOfUsers;
  }

  clearAllData() {
    console.log("clearAllDate");
    this.database.clearAllData();
  }
}
